import React, { useRef, useEffect } from "react";
import { useScrollPercentage } from "../../../hooks/useScrollPercentage";

function ScrollTrackedComponent({
  offset = 10,
  percentageAnimationMap = {},
  children,
  ...props
}) {
  const containerRef = useRef(null);
  const [visibilityPercentage, handleScroll] = useScrollPercentage(offset);

  useEffect(() => {
    const scrollHandler = () => handleScroll(containerRef);
    window.addEventListener("scroll", scrollHandler);

    return () => window.removeEventListener("scroll", scrollHandler);
  }, [handleScroll]);

  const getStyleForPercentage = () => {
    let style = {};

    Object.keys(percentageAnimationMap).forEach((key) => {
      const percentage = parseFloat(key);
      if (visibilityPercentage >= percentage) {
        style = {
          ...style,
          ...percentageAnimationMap[key],
        };
      }
    });

    return style;
  };

  const style = {
    ...props.style,
    "--scroll-percentage": `${visibilityPercentage}%`,
    ...getStyleForPercentage(),
  };

  return (
    <div ref={containerRef} style={style} {...props}>
      {children}
    </div>
  );
}

export default ScrollTrackedComponent;
