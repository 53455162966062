import React from "react";
import { Image } from "react-bootstrap";
import useInViewFromBottom from "../../../../../hooks/useInViewFromBottom";

const ProjectImg = (props) => {
  const { ref, inViewFromBottom } = useInViewFromBottom(0.2);

  return (
    <div
      className={`project-img ${
        inViewFromBottom ? "project-img--inView" : "project-img--notInView"
      }`}
      ref={ref}
    >
      <Image
        src={props.imgSrc}
        alt={props.imgAlt}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </div>
  );
};

export default ProjectImg;
