import React from "react";
import useInViewFromBottom from "../../../../../hooks/useInViewFromBottom";
import Image from "react-bootstrap/Image";

const ProfileDescription = () => {
  const { ref, inViewFromBottom } = useInViewFromBottom(0.3);

  return (
    <div ref={ref} className="profile-desc">
      <Image
        src="/images/profile.png"
        width="300"
        height="300"
        alt="Profile"
        className="profile-desc__pic"
        style={
          inViewFromBottom
            ? { animation: "fade-in-from-left 0.5s ease-out forwards" }
            : {}
        }
      />
      <div
        className="profile-desc__text"
        style={
          inViewFromBottom
            ? { animation: "fade-in-from-right 0.5s ease-out forwards" }
            : {}
        }
      >
        <p>
          Welcome to my portfolio! My name is{" "}
          <span className="text-accent1 fw-bold">Giovanni</span>, I am{" "}
          <span className="text-accent2 fw-bold">24</span>, and I am a{" "}
          <span className="text-accent3 fw-bold">
            Bachelor of Computer Science
          </span>{" "}
          from{" "}
          <span className="text-accent4 fw-bold">
            Ca' Foscari University of Venice
          </span>
          , I am currently working as a{" "}
          <span className="text-accent3 fw-bold">Full Stack developer</span> at{" "}
          <span className="text-accent5 fw-bold">Alpenite</span>.
        </p>
        <p>
          My curiosity and passion for IT began in{" "}
          <span className="text-accent3 fw-bold">high school</span> and
          continued through{" "}
          <span className="text-accent4 fw-bold">university</span>, where I
          developed a strong foundation in software development. After
          graduating, I launched my{" "}
          <span className="text-accent5 fw-bold">career</span> as a software
          developer specializing in Salesforce Commerce Cloud B2C e-commerce,
          with expertise in both{" "}
          <span className="text-primary fw-bold">front-end</span> and{" "}
          <span className="text-secondary fw-bold">back-end</span> development.
          I am always eager to learn new technologies and tackle{" "}
          <span className="text-accent1 fw-bold">new challenges</span>,
          continuously evolving within this dynamic industry.
        </p>
      </div>
    </div>
  );
};

export default ProfileDescription;
