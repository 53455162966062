import React from "react";
import Image from "react-bootstrap/Image";

const TechCarousel = (props) => {
  return (
    <div
      className={`tech-carousel ${props.className} ${
        props.compact ? "tech-carousel--compact" : ""
      }`}
      style={{ "--child-count": props.techs.length }}
    >
      {props.techs.map((tech, index) => {
        return (
          <div
            className="tech-carousel__tile"
            key={index}
            style={{
              "--child-index": index,
              ...(props.reverse && { animationDirection: "reverse" }),
            }}
          >
            <Image
              src={tech.img}
              alt="tech"
              className="tech-carousel__tile--image"
            />
            <p className="tech-carousel__tile--text">{tech.txt}</p>
          </div>
        );
      })}
    </div>
  );
};

export default TechCarousel;
