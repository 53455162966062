import { useState } from "react";

export function useScrollPercentage(offsetPercent = 0) {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const handleScroll = (elementRef) => {
    if (!elementRef.current) return;

    const { offsetTop, offsetHeight } = elementRef.current;
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;

    const offset = (offsetPercent / 100) * windowHeight;

    const start = windowHeight - offset;
    const end = windowHeight + offsetHeight - offset;

    const progress = Math.min(
      Math.max(
        0,
        (scrollPosition + windowHeight - offsetTop - offset) / (end - start)
      ),
      1
    );

    setScrollPercentage(progress * 100);
    
  };

  return [scrollPercentage, handleScroll];
}
