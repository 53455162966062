import { useState } from "react";
import { useInView } from "react-intersection-observer";

const useInViewFromBottom = (intersectionThreshold = 0.5) => {
  const [inViewFromBottom, setInViewFromBottom] = useState(false);

  const { ref } = useInView({
    threshold: [0, intersectionThreshold, 1],
    onChange: (_, entry) => {
      if (entry) {
        const { boundingClientRect, rootBounds, intersectionRatio } = entry;
        if (intersectionRatio >= intersectionThreshold) {
          setInViewFromBottom(true);
        } else if ( boundingClientRect.top >= rootBounds.bottom && intersectionRatio === 0 ) {
          setInViewFromBottom(false);
        }
      }
    },
  });

  return { ref, inViewFromBottom };
};

export default useInViewFromBottom;
