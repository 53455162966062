import './MovesListStyle.css';
import React from "react";
import { Component } from "react";

const level = {
    1: {name:"excellent", color: "#4fb4bf", img : new Image()},
    2: {name:"good", color: "#4caf50", img : new Image()},
    3: {name:"inaccurate", color: "#ffeb3b", img : new Image()},
    4: {name:"mistake", color: "#ffa726", img : new Image()},
    5: {name:"blunder", color: "#ff5f52", img : new Image()}
}

export default class MovesList extends Component {

    constructor(props) {
        super(props);
        this.list = React.createRef();
        this.undoMoveList = [];
        this.redoMoveList = [];
        level[1].img.src = "/projects/chess/icons/1_excellent.svg";
        level[2].img.src = "/projects/chess/icons/2_good.svg";
        level[3].img.src = "/projects/chess/icons/3_inaccurate.svg";
        level[4].img.src = "/projects/chess/icons/4_mistake.svg";
        level[5].img.src = "/projects/chess/icons/5_blunder.svg";
    }

    render() {
            return  <div className='gameMovesContainer enable' ref={this.list}></div>;
    }

    pushMove(move, san, flags){
        let moveSpan = document.createElement('span');
        let moveNumber = this.undoMoveList.length;
        moveSpan.classList.add("gameMove");
        moveSpan.innerHTML = san;
        moveSpan.addEventListener("mousedown", () => {
            if(this.props.onMoveClick && typeof (this.props.onMoveClick) === "function")
                this.props.onMoveClick(moveNumber);
        });
        moveSpan.addEventListener("mouseenter", () => {
            if(this.props.onMoveEnter && typeof (this.props.onMoveEnter) === "function")
                this.props.onMoveEnter(move, moveNumber);
            if(this.list.current.classList.contains("enable")){
                let move = this.undoMoveList[moveNumber].move;
                let level = this.undoMoveList[moveNumber].moveLevel;
                let alt = this.undoMoveList[moveNumber-1].altMove;
                if(move && alt){
                    if(level !== 1){
                        this.drawArrow(alt.substring(0,2), alt.substring(2,4), 1)
                    }
                    this.drawArrow(move.substring(0,2), move.substring(2,4), level)
                }
            }
        });
        moveSpan.addEventListener("mouseleave", () => {
            if(this.props.onMoveLeave && typeof (this.props.onMoveLeave) === "function")
                this.props.onMoveLeave();
            if(this.list.current.classList.contains("enable")){
                let c = document.getElementById("arrowCanvas");
                c.getContext('2d').clearRect(0, 0, c.width, c.height);
            }
        });
        this.list.current.appendChild(moveSpan);
        this.undoMoveList.push({move:move, eval:null, depth:-1, altMove:null, flags:flags, san:san});
    }

    undoMove(){
        this.list.current.removeChild(this.list.current.lastChild);
        this.redoMoveList.push(this.undoMoveList.pop());
        let moveToUndo = this.undoMoveList[this.undoMoveList.length-1];
        let lastChild = this.list.current.lastChild;
        if (this.props.onUndoRedo && typeof (this.props.onUndoRedo) === "function" && moveToUndo.flags) {
            this.props.onUndoRedo(moveToUndo);
        }
        if(lastChild) lastChild.dispatchEvent(new Event("mouseenter"));
        return moveToUndo.move;
    }

    redoMove(isBlackMove){
        let moveToRedo = this.redoMoveList.pop();
        this.pushMove(moveToRedo.move, moveToRedo.san, moveToRedo.flags);
        this.showEvaluation(moveToRedo.eval, isBlackMove, moveToRedo.depth, moveToRedo.altMove);
        let lastChild = this.list.current.lastChild;
        if (this.props.onUndoRedo && typeof (this.props.onUndoRedo) === "function" && moveToRedo.flags) {
            this.props.onUndoRedo(moveToRedo);
        }
        if(lastChild) lastChild.dispatchEvent(new Event("mouseenter"));
        return moveToRedo.move;
    }

    undoAll(){
        this.list.current.innerHTML = "";
        while(this.undoMoveList.length > 1){
            this.redoMoveList.push(this.undoMoveList.pop());
        }
    }

    emptyList(){
        this.list.current.innerHTML = "";
        this.undoMoveList = [];
        this.redoMoveList = [];
    }

    getMoveList(){
        let moves = [];
        [...this.undoMoveList].forEach(e => {
            moves.push(e.move)
        });
        return moves.toString();
    }

    getMove(i){
        return this.undoMoveList[i].move;
    }

    showEvaluation(evaluation, isBlackMove, depth, altMove){
        let l = this.undoMoveList.length;
        if(l > 0){
            if(Number(depth) > this.undoMoveList[l-1].depth){
                this.undoMoveList[l-1].eval = evaluation;
                this.undoMoveList[l-1].depth = Number(depth);
                this.undoMoveList[l-1].altMove = altMove;
                let lastEval = this.undoMoveList[l-2];
                if(lastEval){
                    let moveEval = (isBlackMove ? -1 : 1) * (evaluation - lastEval.eval);
                    let moveLevel;
                    if(moveEval > 0.35){
                        if(moveEval > 1){
                            if(moveEval > 2){
                                if(moveEval > 3){
                                    moveLevel = 5;
                                }else{
                                    moveLevel = 4;
                                }
                            }else{
                                moveLevel = 3;
                            }
                        }else{
                            moveLevel = 2;
                        }
                    }else{
                        moveLevel = 1;
                    }
                    this.setEvaluation(l-2, moveLevel);
                    this.undoMoveList[l-1].moveLevel = moveLevel;
                }
            }
        }else{
            this.undoMoveList.push({move:"startpos", eval:null, depth:null, altMove:null});
        }
    }

    setEvaluation(pos, evalLevel){

        let elem = this.list.current.childNodes[pos];

        if(elem){

            let toRemoveClass = elem.classList[1];
            if(toRemoveClass) elem.classList.remove(toRemoveClass);
            elem.classList.add(level[evalLevel].name);
            elem.setAttribute("data-tooltip", elem.innerHTML + " is " + level[evalLevel].name);

        }

    }

    toggle(){
        if(this.list.current.classList.contains("enable")){
            this.list.current.classList.remove("enable");
        }else{
            this.list.current.classList.add("enable");
        }
    }

    drawArrow(from, to, evalLevel) {

        let c = document.getElementById("arrowCanvas");

        let fromSquare = document.getElementById(from)
        let toSquare = document.getElementById(to);

        if(fromSquare && toSquare){

            let color = level[evalLevel].color;

            //variables to be used when creating the arrow
            let offset = vmin(5);
            let fromx = fromSquare.getBoundingClientRect().left - c.getBoundingClientRect().left + offset;
            let fromy = fromSquare.getBoundingClientRect().top - c.getBoundingClientRect().top + offset;
            let tox = toSquare.getBoundingClientRect().left - c.getBoundingClientRect().left + offset;
            let toy = toSquare.getBoundingClientRect().top - c.getBoundingClientRect().top + offset;
            let ctx = c.getContext("2d");
            let headlen = offset/3;

            let angle = Math.atan2(toy - fromy, tox - fromx);

            //starting path of the arrow from the start square to the end square and drawing the stroke
            ctx.beginPath();
            ctx.moveTo(fromx, fromy);
            ctx.lineTo(tox, toy);
            ctx.strokeStyle = color;
            ctx.lineWidth = offset / 3;
            ctx.stroke();

            //starting a new path from the head of the arrow to one of the sides of the point
            ctx.beginPath();
            ctx.moveTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

            //path from the side point of the arrow, to the other side point
            ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 7), toy - headlen * Math.sin(angle + Math.PI / 7));

            //path from the side point back to the tip of the arrow, and then again to the opposite side point
            ctx.lineTo(tox, toy);
            ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 7), toy - headlen * Math.sin(angle - Math.PI / 7));

            //draws the paths created above
            ctx.strokeStyle = color;
            ctx.lineWidth = offset / 2;
            ctx.stroke();
            ctx.fillStyle = color;
            ctx.fill();

            //draw icon img
            let x = tox + ((fromx - tox)/2);
            let y = toy + ((fromy - toy)/2);
            ctx.fillStyle = "#000000";
            let circle = new Path2D();
            circle.arc(x, y, offset/2+vmin(.2), 0, 2 * Math.PI);
            ctx.fill(circle);
            ctx.drawImage(level[evalLevel].img, x - (offset/2), y - (offset/2), offset, offset);

        }
    }

}

function vh(v) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

function vw(v) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

function vmin(v) {
    return Math.min(vh(v), vw(v));
}