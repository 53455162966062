import React from "react";
import { Image, Button } from "react-bootstrap";
import useInViewFromBottom from "../../../../../hooks/useInViewFromBottom";

const ProjectCard = (props) => {
  const { ref, inViewFromBottom } = useInViewFromBottom(0.2);

  return (
    <div
      className={`project-card ${
        inViewFromBottom ? "project-card--inView" : "project-card--notInView"
      }`}
      ref={ref}
    >
      <div className="project-card__img">
        <Image
          src={props.imgSrc}
          alt={props.imgAlt}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="project-card__desc">
        <h2 className="project-card__desc--title">{props.title}</h2>
        <Image
          className="project-card__desc--icon"
          src={props.iconSrc}
          alt="projIcon"
        />
        <p className="project-card__desc--content">{props.children}</p>
        <div className="project-card__desc--ctas">
          {props.ctas &&
            props.ctas.map((cta, index) => (
              <Button
                key={index}
                className="w-100"
                variant={cta.color}
                href={cta.url}
              >
                {cta.text}
              </Button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
