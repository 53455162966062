import React from "react";
import { Card } from "react-bootstrap";

function ContactCard({
  icon: Icon,
  iconColor = "primary",
  line1,
  line2,
  href,
}) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ cursor: "pointer", textDecoration: "none" }}
    >
      <Card className="bg-dark mb-4">
        <Card.Body className="d-flex align-items-center">
          <Icon
            size={40}
            className={`flex-shrink-0 text-primary me-5 text-${iconColor}`}
          />
          <div className="d-flex flex-column justify-content-center overflow-hidden">
            <p className="fs-18 mb-1">{line1}</p>
            <p className="fs-14 mb-0">{line2}</p>
          </div>
        </Card.Body>
      </Card>
    </a>
  );
}

export default ContactCard;
