import React from "react";
import { useGLTF } from "@react-three/drei";

const Glasses = (props) => {
  const { nodes, materials } = useGLTF("/models/glasses.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, -Math.PI / 4]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.defaultMaterial.geometry}
          material={materials.DefaultMaterial}
          rotation={[Math.PI / 2, 0, 0]}
        />
      </group>
    </group>
  );
};

useGLTF.preload("/models/glasses.glb");

export default Glasses;
