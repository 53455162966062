import React from "react";
import ScrollTrackedComponent from "../../../../common/scrollTrackedComponent/ScrollTrackedComponent";
import TimelineExperience from "./components/timelineExperience";
import TechCarousel from "../techCarousel/techCarousel";

function Timeline() {
  return (
    <>
      <ScrollTrackedComponent
        className="portfolio-timeline"
        offset={50}
        percentageAnimationMap={{
          0: {
            "--timeline-scroll-color": "#76c203",
            "--experience-1-color": "#efefef",
            "--experience-2-color": "#efefef",
            "--experience-3-color": "#efefef",
          },
          10: {
            "--timeline-scroll-color": "#76c203",
            "--experience-1-color": "#76c203",
          },
          38: {
            "--timeline-scroll-color": "#aa1c41",
            "--experience-2-color": "#aa1c41",
          },
          60: {
            "--timeline-scroll-color": "#013c4d",
            "--experience-3-color": "#013c4d",
          },
        }}
      >
        {/* line */}
        <div className="portfolio-timeline__line"></div>
        {/* time units */}
        <div className="portfolio-timeline__units">
          <div className="portfolio-timeline__units--year">2014</div>
          <div className="portfolio-timeline__units--year">2015</div>
          <div className="portfolio-timeline__units--year">2016</div>
          <div className="portfolio-timeline__units--year">2017</div>
          <div className="portfolio-timeline__units--year">2018</div>
          <div className="portfolio-timeline__units--year">2019</div>
          <div className="portfolio-timeline__units--year">2020</div>
          <div className="portfolio-timeline__units--year">2021</div>
          <div className="portfolio-timeline__units--year">2022</div>
          <div className="portfolio-timeline__units--year">2023</div>
          <div className="portfolio-timeline__units--year">2024</div>
          <div className="portfolio-timeline__units--year">2025</div>
        </div>
        {/* Experience Entries */}
        <TimelineExperience
          unitAmmount={5}
          dotColor="var(--experience-1-color)"
          imageSrc="/icons/zuccante.png"
          imageAlt="Zuccante Logo"
          imageBigSrc="/icons/zuccante-big.png"
          imageBigAlt="Zuccante Logo Big"
          title="I.T.I.S. Carlo Zuccante"
          subtitle="High School Diploma, Computer Science"
          dates="Sep 2014 - Jul 2019"
          grade="82/100"
        >
          <p>
            High School Diploma in Computer Science and Telecommunications
            (TF-21) with a foundation in programming, networking,
            telecommunications systems, and information technology principles.
          </p>
          <TechCarousel
            compact
            techs={[
              { img: "/icons/git.png", txt: "Git" },
              { img: "/icons/mysql.png", txt: "MySql" },
              { img: "/icons/mongodb.png", txt: "MongoDB" },
              { img: "/icons/c++.png", txt: "C++" },
              { img: "/icons/csharp.png", txt: "C#" },
              { img: "/icons/java.png", txt: "Java" },
              { img: "/icons/android.png", txt: "Android" },
              { img: "/icons/html.png", txt: "Html" },
              { img: "/icons/css.png", txt: "Css" },
              { img: "/icons/materialize.png", txt: "Materialize" },
            ]}
          />
        </TimelineExperience>

        <TimelineExperience
          unitAmmount={4}
          alignment="left"
          dotColor="var(--experience-2-color)"
          imageSrc="/icons/cafoscari.png"
          imageAlt="Ca' Foscari Logo"
          imageBigSrc="/icons/cafoscari-big.png"
          imageBigAlt="Ca' Foscari Logo Big"
          title="Ca' Foscari University of Venice"
          subtitle="Bachelor's degree, Computer Science"
          dates="Sep 2019 - Jul 2023"
          grade="97/110"
        >
          <p>
            Bachelor’s Degree in Computer Science (L-31) with a focus on
            calculus, statistics, linear algebra, optimization, algorithms, data
            structures, computational theory, networking, software development,
            cybersecurity, and web development.
          </p>
          <TechCarousel
            compact
            techs={[
              { img: "/icons/postgresql.png", txt: "PostgreSQL" },
              { img: "/icons/mongodb.png", txt: "MongoDB" },
              { img: "/icons/node.png", txt: "Node" },
              { img: "/icons/express-js.png", txt: "ExpressJs" },
              { img: "/icons/javascript.png", txt: "Javascript" },
              { img: "/icons/react.png", txt: "React" },
              { img: "/icons/typescript.png", txt: "Typescript" },
              { img: "/icons/angular.png", txt: "Angular" },
              { img: "/icons/bootstrap.png", txt: "Bootstrap" },
              { img: "/icons/python.png", txt: "Python" },
              { img: "/icons/fsharp.png", txt: "F#" },
              { img: "/icons/c.png", txt: "C" },
            ]}
          />
        </TimelineExperience>

        <TimelineExperience
          unitAmmount={2}
          dotColor="var(--experience-3-color)"
          imageSrc="/icons/alpenite.png"
          imageAlt="Alpenite Logo"
          imageBigSrc="/icons/alpenite-big.png"
          imageBigAlt="Alpenite Logo Big"
          title="Alpenite"
          subtitle="Full Stack SFCC Developer"
          dates="Oct 2023 - Present"
        >
          <p>
            Full Stack Developer specializing in B2C e-commerce using Salesforce
            Commerce Cloud. Working as a consultant on projects for luxury
            fashion brands within the LVMH group.
          </p>
          <p>
            Mainly worked on the Loro Piana replatforming project, transitioning
            from a large legacy Hybris e-commerce system to a new Salesforce
            Commerce Cloud solution, incorporating extensive customizations and
            integrations. Began as a FE developer, crafting custom user
            experiences using ISML, SCSS, JS, jQuery, Bootstrap, React... and
            gradually also got into BE development, working with controllers,
            models, cartridges, custom objects and preferences, site
            configurations, integrations, services, jobs...
          </p>
          <TechCarousel
            compact
            techs={[
              { img: "/icons/jira.png", txt: "Jira" },
              { img: "/icons/bitbucket.png", txt: "Bitbucket" },
              { img: "/icons/commerce.png", txt: "Ecommerce" },
              { img: "/icons/salesforce.png", txt: "Salesforce" },
              { img: "/icons/javascript.png", txt: "Javascript" },
              { img: "/icons/react.png", txt: "React" },
              { img: "/icons/jquery.png", txt: "jQuery" },
              { img: "/icons/html.png", txt: "ISML" },
              { img: "/icons/sass.png", txt: "Scss" },
              { img: "/icons/bootstrap.png", txt: "Bootstrap" },
            ]}
          />
        </TimelineExperience>
      </ScrollTrackedComponent>
    </>
  );
}

export default Timeline;
