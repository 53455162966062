import React, { useRef, useState } from "react";
import { animated, useSpring } from "@react-spring/three";
import { Canvas } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { Button } from "react-bootstrap";
import Macbook from "../../../models/Macbook";
import HeroCamera from "./HeroCamera";

export default function MacbookHero({ scrollToForm }) {
  // Handle window hovering
  const isWindowHoveredRef = useRef(false);
  const setHoverState = (isHovered) => {
    isWindowHoveredRef.current = isHovered;
  };

  // Handle window closing
  const [isWindowOpen, setIsWindowOpen] = useState(true);
  const isWindowOpenRef = useRef(true);
  const toggleWindowOpen = () => {
    setIsWindowOpen(!isWindowOpen);
    isWindowOpenRef.current = !isWindowOpenRef.current;
    setWindowSpring.start({
      windowScale: isWindowOpenRef.current ? 1 : 0.1,
      windowPosition: isWindowOpenRef.current ? 0 : 3.5,
    });
  };
  const [windowSpring, setWindowSpring] = useSpring(() => ({
    windowScale: isWindowOpenRef.current ? 1 : 0.1,
    windowPosition: isWindowOpenRef.current ? 0 : 3.5,
    config: { tension: 400, friction: 30, clamp: true },
  }));

  return (
    <Canvas className="macbook-hero-canvas">
      <HeroCamera
        isWindowHoveredRef={isWindowHoveredRef}
        windowHoveredRotation={[0.1, 0, 0]}
      >
        <group scale={0.8} position={[0, -2, 0]} rotation={[0.25, 0, 0]}>
          <Macbook />
          <animated.group
            scale={windowSpring.windowScale}
            position={windowSpring.windowPosition.to((s) => [0, s, -3 * s])}
          >
            <Html
              transform
              position={[0, 12, -16]}
              rotation={[-Math.PI / 8, 0, 0]}
              scale={5}
            >
              <div
                className="macbook-window"
                onPointerEnter={() => setHoverState(true)}
                onPointerLeave={() => setHoverState(false)}
              >
                <div
                  className={`macbook-reopen-window-icon ${
                    isWindowOpen ? "closed" : ""
                  }`}
                  onClick={toggleWindowOpen}
                ></div>
                <div className="macbook-window__header">
                  <div
                    className="macbook-window__button macbook-window__button--red"
                    onClick={toggleWindowOpen}
                  >
                    x
                  </div>
                  <div
                    className="macbook-window__button macbook-window__button--yellow"
                    onClick={toggleWindowOpen}
                  >
                    −
                  </div>
                  <div className="macbook-window__button macbook-window__button--green">
                    ⤡
                  </div>
                </div>
                <div className="macbook-window__body">
                  <p>Welcome to my Contact Page. Let's get in touch!</p>
                </div>
                <div className="macbook-window__footer">
                  <Button
                    className="btn btn-accent3"
                    size="sm"
                    onClick={scrollToForm}
                  >
                    Contact
                  </Button>
                </div>
              </div>
            </Html>
          </animated.group>
        </group>
      </HeroCamera>

      {/* Lights */}
      <ambientLight intensity={0.5} />
      <directionalLight position={[-5, 10, 10]} intensity={1} castShadow />
    </Canvas>
  );
}
