import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useFrame } from "@react-three/fiber";
import { easing } from "maath";

export default function HeroCamera({
  children,
  isWindowHoveredRef,
  windowHoveredRotation,
}) {
  const group = useRef();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useFrame((state, delta) => {
    easing.damp3(
      state.camera.position,
      [0, 0, isMobile ? 40 : 20],
      0.25,
      delta
    );
    easing.dampE(
      group.current.rotation,
      isWindowHoveredRef?.current
        ? windowHoveredRotation || [0, 0, 0]
        : [-state.pointer.y / 6, state.pointer.x / 10, 0],
      0.25,
      delta
    );
  });

  return <group ref={group}>{children}</group>;
}
