import React from "react";
import ProfileDescription from "./components/profileDescription/profileDescription";
import Timeline from "./components/timeline/timeline";
import SectionDescription from "./components/sectionDescription/sectionDescription";
import TechCarousel from "./components/techCarousel/techCarousel";
import useInViewFromBottom from "../../../hooks/useInViewFromBottom";

function Home() {
  const { ref, inViewFromBottom } = useInViewFromBottom(0.1);

  return (
    <>
      <div className="sticky-container">
        <div
          className={`hero-image ${inViewFromBottom ? "animate" : ""}`}
        ></div>
        <div className="logo-container">
          <h1
            className={`fs-64 mb-5 text-primary animate-letters animate-letters--left animate-letters--openbracket ${
              inViewFromBottom ? "animate" : ""
            }`}
          >
            <div className={"first-part"}>S</div>
            <div className={`second-part ${inViewFromBottom ? "animate" : ""}`}>
              tevanato
            </div>
          </h1>
          <h1
            className={`fs-64 mb-5 text-secondary animate-letters animate-letters--right animate-letters--closebracket ${
              inViewFromBottom ? "animate" : ""
            }`}
          >
            <div className={"first-part"}>G</div>
            <div className={`second-part ${inViewFromBottom ? "animate" : ""}`}>
              iovanni
            </div>
          </h1>
        </div>
      </div>

      <div ref={ref}></div>

      <ProfileDescription />

      <TechCarousel
        className="mb-7"
        techs={[
          { img: "/icons/calendar.png", txt: "23 Sept 2000" },
          { img: "/icons/zuccante.png", txt: "ITIS Carlo Zuccante" },
          { img: "/icons/cafoscari.png", txt: "Ca' Foscari University" },
          { img: "/icons/alpenite.png", txt: "Alpenite" },
          { img: "/icons/italian.png", txt: "Italian Native" },
          { img: "/icons/englishC1.png", txt: "English C1" },
        ]}
      />

      <Timeline />

      <SectionDescription
        reverse
        imgSrc="/images/degree.jpg"
        imgAlt="section1"
        picDirection="right"
        textDirection="right"
      >
        <p className="text-primary fw-bold fs-26">Building a Passion for IT</p>
        <p>
          My journey into the world of IT began in high school at{" "}
          <span className="text-accent3 fw-bold">ITIS Carlo Zuccante</span>,
          where my curiosity for IT quickly grew into a passion. I was
          fascinated by how programming and networking concepts could bring
          complex systems to life, and this excitement only intensified as I
          gained more knowledge.
        </p>
        <p>
          Driven by this newfound interest, I pursued a Bachelor’s degree in
          Computer Science at{" "}
          <span className="text-accent4 fw-bold">
            Ca’ Foscari University of Venice
          </span>
          , immersing myself in the intricacies of mathematics, software
          development, algorithms, data structures, and cybersecurity. Each
          course expanded my understanding, transforming what began as curiosity
          into a solid technical foundation.
        </p>
        <p>
          Today, I bring that same curiosity and drive to explore new
          technologies, now supported by years of experience and deepened
          expertise in the field.
        </p>
      </SectionDescription>

      <SectionDescription
        imgSrc="/images/alpenite.jpeg"
        imgAlt="section2"
        picDirection="left"
        textDirection="left"
      >
        <p className="text-primary fw-bold fs-26">Starting a Career in IT</p>
        <p>
          After completing my degree, I launched my career as a software
          developer specializing in{" "}
          <span className="text-accent1 fw-bold">
            Salesforce Commerce Cloud (SFCC)
          </span>{" "}
          and <span className="text-accent2 fw-bold">B2C e-commerce</span>.
          Beginning as a{" "}
          <span className="text-primary fw-bold">Front-End Developer</span>, I
          focused on transforming Figma designs into engaging and user-friendly
          digital storefronts. Over time, I expanded my skill set to include{" "}
          <span className="text-secondary fw-bold">Back-End Development</span>,
          gaining in-depth knowledge of the platform and its extensive
          customizations.
        </p>
        <p>
          My main focus at{" "}
          <span className="text-accent5 fw-bold">Alpenite</span> has been
          developing e-commerce platforms for{" "}
          <span className="text-accent3 fw-bold">
            luxury high-fashion brand
          </span>{" "}
          clients as a consultant, which has given me deep, specialized
          knowledge in the e-commerce field.
        </p>
      </SectionDescription>

      <SectionDescription
        reverse
        imgSrc="/images/lights.jpg"
        imgAlt="section3"
        picDirection="right"
        textDirection="left"
      >
        <p className="text-primary fw-bold fs-26">What is next?</p>
        <p>
          I’m excited to take the{" "}
          <span className="text-accent1 fw-bold">next step in my career</span>,
          whether that’s deepening my experience in e-commerce or exploring
          entirely new fields with fresh challenges. My background has prepared
          me to adapt quickly and grow with new technologies and industry
          trends, so I’m open to roles where I can build on my skills and keep
          expanding my knowledge.
        </p>
        <p>
          I’m looking forward to stepping into{" "}
          <span className="text-accent2 fw-bold">new opportunities</span>,
          learning along the way, and making a meaningful impact on any team I
          join. Please feel free to contact me about any new opportunities!
        </p>
      </SectionDescription>
    </>
  );
}

export default Home;
