import React, { useRef } from "react";
import { Canvas } from "@react-three/fiber";
import ComputerStack from "../../../../models/ComputerStack";
import HeroCamera from "../../../contact/macbookHero/HeroCamera";
import { Html } from "@react-three/drei";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { EffectComposer, Bloom } from "@react-three/postprocessing";

export default function ComputerStackHero({ scrollToProjects }) {
  // Handle window hovering
  const isWindowHoveredRef = useRef(false);
  const setHoverState = (isHovered) => {
    isWindowHoveredRef.current = isHovered;
  };
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Canvas className="computer-stack-hero-canvas">
      <HeroCamera
        isWindowHoveredRef={isWindowHoveredRef}
        windowHoveredRotation={[-0.15, 0, 0]}
      >
        <group>
          <ComputerStack
            transform
            scale={isMobile ? 2.9 : 2.4}
            position={isMobile ? [-0.8, 4, 0] : [0, -2.5, 0]}
            rotation={[0.3, 0, 0]}
          />
          <Html
            transform
            scale={isMobile ? 2 : 1.2}
            position={isMobile ? [0, -10, 0] : [0, -8, 0]}
          >
            <div
              style={{ width: "600px" }}
              onPointerEnter={() => setHoverState(true)}
              onPointerLeave={() => setHoverState(false)}
            >
              <h3 className="text-center mb-6">
                Here are some of the projects I worked on!
              </h3>
              <div className="d-flex justify-content-center">
                <Button
                  className="btn btn-accent1"
                  size="lg"
                  onClick={scrollToProjects}
                >
                  Discover More
                </Button>
              </div>
            </div>
          </Html>
        </group>
      </HeroCamera>
      {/* Lights */}
      <ambientLight intensity={0.05} />
      <directionalLight position={[-5, 20, 10]} intensity={0.1} castShadow />
      <EffectComposer>
        <Bloom
          intensity={1.3}
          luminanceThreshold={0.1}
          luminanceSmoothing={0.1}
        />
      </EffectComposer>
    </Canvas>
  );
}
