import React from "react";
import Image from "react-bootstrap/Image";
import useInViewFromBottom from "../../../../../hooks/useInViewFromBottom";

const SectionDescription = (props) => {
  const { ref, inViewFromBottom } = useInViewFromBottom(0.2);

  return (
    <div
      ref={ref}
      className={`section-desc
        ${props.reverse ? "section-desc--reverse" : ""}
        ${
          inViewFromBottom ? "section-desc--inView" : "section-desc--notInView"
        }`}
    >
      <Image
        src={props.imgSrc}
        alt={props.imgAlt}
        className={`section-desc__pic 
            ${
              props.picDirection === "right"
                ? "section-desc__pic--right"
                : props.picDirection === "left"
                ? "section-desc__pic--left"
                : ""
            }`}
      />
      <div
        className={`section-desc__text 
        ${
          props.textDirection === "right"
            ? "section-desc__text--right"
            : props.textDirection === "left"
            ? "section-desc__text--left"
            : ""
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default SectionDescription;
