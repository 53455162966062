import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import ProjectImg from "./components/ProjectImg/projectImg";
import ProjectCard from "./components/ProjectCard/projectCard";
import ComputerStackHero from "./components/ComputerStackHero/ComputerStackHero";
import p from "./projects.json";

function Portfolio() {
  const projectsRef = useRef(null);

  // Scroll to the projects function
  const scrollToProjects = () => {
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <ComputerStackHero scrollToProjects={scrollToProjects} />
      <div ref={projectsRef} className="projects-plp-grid">
        {/* PROJECT 1 : ANDROID */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["android"].images[1].src}
            imgAlt={p["android"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "1" }}
        >
          <ProjectCard
            imgSrc={p["android"].images[0].src}
            imgAlt={p["android"].images[0].alt}
            iconSrc={p["android"].icon}
            title={p["android"].title}
            ctas={[
              {
                text: "View details",
                color: "primary",
                url: "/portfolio/android",
              },
            ]}
          >
            {p["android"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 2: SURVEY */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["survey"].images[2].src}
            imgAlt={p["survey"].images[2].alt}
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["survey"].images[1].src}
            imgAlt={p["survey"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "3" }}
        >
          <ProjectCard
            imgSrc={p["survey"].images[0].src}
            imgAlt={p["survey"].images[0].alt}
            iconSrc={p["survey"].icon}
            title={p["survey"].title}
            ctas={[
              {
                text: "View details",
                color: "primary",
                url: "/portfolio/survey",
              },
              {
                text: "Git Repo",
                color: "accent1",
                url: p["survey"].gitUrl,
              },
            ]}
          >
            {p["survey"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 3 : FAMILIES SHARE*/}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["families"].images[1].src}
            imgAlt={p["families"].images[1].alt}
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectCard
            imgSrc={p["families"].images[0].src}
            imgAlt={p["families"].images[0].alt}
            iconSrc={p["families"].icon}
            title={p["families"].title}
            ctas={[
              {
                text: "View details",
                color: "primary",
                url: "/portfolio/families",
              },
            ]}
          >
            {p["families"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 4: CHESS */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["chess"].images[2].src}
            imgAlt={p["chess"].images[2].alt}
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["chess"].images[1].src}
            imgAlt={p["chess"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "7" }}
        >
          <ProjectCard
            imgSrc={p["chess"].images[0].src}
            imgAlt={p["chess"].images[0].alt}
            iconSrc={p["chess"].icon}
            title={p["chess"].title}
            ctas={[
              {
                text: "View details",
                color: "primary",
                url: "/portfolio/chess",
              },
              {
                text: "Play Demo",
                color: "accent1",
                url: "/portfolio/chessdemo",
              },
            ]}
          >
            {p["chess"].description}
          </ProjectCard>
        </div>
        {/* END OF CYCLE */}
        {/* PROJECT 5 : BATTLESHIP */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["battleship"].images[1].src}
            imgAlt={p["battleship"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "9" }}
        >
          <ProjectCard
            imgSrc={p["battleship"].images[0].src}
            imgAlt={p["battleship"].images[0].alt}
            iconSrc={p["battleship"].icon}
            title={p["battleship"].title}
            ctas={[
              {
                text: "View details",
                color: "primary",
                url: "/portfolio/battleship",
              },
            ]}
          >
            {p["battleship"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 6: LOROPIANA */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["loropiana"].images[2].src}
            imgAlt={p["loropiana"].images[2].alt}
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc={p["loropiana"].images[1].src}
            imgAlt={p["loropiana"].images[1].alt}
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "11" }}
        >
          <ProjectCard
            imgSrc={p["loropiana"].images[0].src}
            imgAlt={p["loropiana"].images[0].alt}
            iconSrc={p["loropiana"].icon}
            title={p["loropiana"].title}
            ctas={[
              {
                text: "View details",
                color: "primary",
                url: "/portfolio/loropiana",
              },
              {
                text: "View Live Website",
                color: "accent1",
                url: "https://it.loropiana.com/en/",
              },
            ]}
          >
            {p["loropiana"].description}
          </ProjectCard>
        </div>
        {/* PROJECT 7 : PLACEHOLDER */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        {/* PROJECT 8 : PLACEHOLDER */}
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        <div className="projects-plp-grid__card">
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        <div
          className="projects-plp-grid__card"
          style={{ "--force-row-index": "15" }}
        >
          <ProjectImg
            imgSrc="/images/placeholder.png"
            imgAlt="placeholderimg"
          />
        </div>
        {/* END OF CYCLE */}
      </div>
    </>
  );
}

export default Portfolio;
