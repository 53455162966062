import React from "react";
import useInViewFromBottom from "../../../../../../hooks/useInViewFromBottom";

function TimelineExperience({
  unitAmmount,
  dotColor,
  imageSrc,
  imageAlt,
  imageBigSrc,
  imageBigAlt,
  title,
  subtitle,
  dates,
  grade,
  children,
  alignment = "right",
}) {
  const { ref, inViewFromBottom } = useInViewFromBottom(0.3);

  return (
    <div
      ref={ref}
      className={"portfolio-timeline__experience"}
      style={{ "--unit-ammount": unitAmmount }}
    >
      <img
        className="portfolio-timeline__experience--dot"
        style={{ backgroundColor: dotColor }}
        src={imageSrc}
        alt={imageAlt}
      />
      <div
        ref={ref}
        className={`portfolio-experience portfolio-experience--${alignment} ${
          inViewFromBottom ? "inView" : ""
        }`}
      >
        <img
          className="portfolio-experience__image"
          src={imageBigSrc}
          alt={imageBigAlt}
        />
        <h3 className="portfolio-experience__title">{title}</h3>
        <p className="portfolio-experience__subtitle">{subtitle}</p>
        <p className="portfolio-experience__extrainfo">{dates}</p>
        {grade && (
          <p className="portfolio-experience__extrainfo">Grade: {grade}</p>
        )}
        <div className="portfolio-experience__description">{children}</div>
      </div>
    </div>
  );
}

export default TimelineExperience;
