import React from 'react';
import { Component } from 'react';
import { renderToString } from 'react-dom/server'
import Tile from './Tile/Tile.js';
import Piece from './Tile/Piece/Piece.js';
import PromotionModal from './Modals/Promotion/PromotionModal.js';
import GameOverModal from './Modals/GameOver/GameOverModal.js';
import './ChessboardStyle.css';
import { Chess } from 'chess.js'
import moveSoundLink from './Sounds/move.mp3';
import captureSoundLink from './Sounds/capture.mp3';
import castleSoundLink from './Sounds/castle.mp3';
import checkSoundLink from './Sounds/check.mp3';
import ggSoundLink from './Sounds/gg.mp3';

const ROWS = ["1", "2", "3", "4", "5", "6", "7", "8"];
const COLUMNS = ["a", "b", "c", "d", "e", "f", "g", "h"];

const moveSound = new Audio (moveSoundLink);
const captureSound = new Audio(captureSoundLink);
const castleSound = new Audio(castleSoundLink);
const checkSound = new Audio(checkSoundLink);
const ggSound = new Audio(ggSoundLink);

export default class Chessboard extends Component {
    constructor(props) {
        super(props);
        this.fen = (this.props.FEN ? this.props.FEN : "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1");
        this.boardRef = React.createRef();
        this.GameOverModal = React.createRef();
        this.game = new Chess(this.fen);
        
        
        this.isWhiteOnBottom = true;
        this.pieceGrabbed = null;
        this.squareSelected = null;
        this.promotingMove = null;
        this.capturedPiece = null;
        this.arrowFrom = null;
        this.playerColor = this.props.playerColor;
    }

    render() {
        let board = [];
        let fen = this.fen.split(" ")[0].split('/').join('');
        let fenPos = 0;
        let skip = 0;
        let canvasSize = vmin(80);
        window.addEventListener("resize", this.resizeCanvas);

        for (let i = ROWS.length - 1; i >= 0; i--) {
            for (let j = 0; j < COLUMNS.length; j++) {

                let piece;
                if (skip === 0) {
                    if (fen[fenPos].match(/[pbnrqkPBNRQK]/)) {
                        piece = fen[fenPos];
                    } else if (fen[fenPos].match(/[1-8]/)) {
                        skip = Number.parseInt(fen[fenPos]) - 1;
                    }
                    fenPos++
                } else {
                    skip--;
                }

                const isDark = ((i + j + 2) % 2) === 0;
                board.push(<Tile key={COLUMNS[j] + ROWS[i]} isDark={isDark} squareName={COLUMNS[j] + ROWS[i]} pieceName={piece} noGrab={this.props.noGrab} onTileClick={this.props.onTileClick}/>);

            }
        }

        return <div ref={this.boardRef} className='Chessboard WhiteOnBottom'
            onMouseDown={(e) => this.handlePointerDown(e)}
            onMouseMove={(e) => this.handlePointerMove(e)}
            onMouseUp={(e) => this.handlePointerUp(e)}
            onTouchStart={(e) => this.handlePointerDown(e)}
            onTouchMove={(e) => this.handlePointerMove(e)}
            onTouchEnd={(e) => this.handlePointerUp(e)}
            onContextMenu={e => e.preventDefault()}>
            {board}
            <canvas id="arrowCanvas" width={canvasSize} height={canvasSize}></canvas>
            <div id="modals">
                <PromotionModal promoteTo={p => this.promoteTo(p, true)}></PromotionModal>
                <GameOverModal ref={this.GameOverModal} buttonMessage={this.props.endGameButtonMessage} restartGame={e => this.restartGame()}></GameOverModal>
            </div>
        </div>;
    }

    componentDidMount() {
        if (this.playerColor === "black" && this.isWhiteOnBottom) {
            this.rotateBoard();
        }
    }

    handlePointerDown(e) {
        const isLeftClick = e.type === "mousedown" && e.button === 0;
        const isTouch = e.type === "touchstart";
    
        if (isLeftClick || isTouch) {
            const playerToMove = this.game.turn() === "w" ? "white" : "black";
    
            const target = isTouch ? e.touches[0].target : e.target;
    
            if (
                target.classList.contains(playerToMove) &&
                (this.playerColor === "both" || this.playerColor === playerToMove)
            ) {
                this.grabPiece(e);
                e.stopPropagation();
            } else {
                this.removeMarks();
            }
        } else if (e.button === 2) {
            this.startArrow(e);
        }
    }
    
    handlePointerUp(e) {
        const isLeftClick = e.type === "mouseup" && e.button === 0;
        const isTouch = e.type === "touchend";

        if (isLeftClick || isTouch) {
            this.releasePiece(e);
            e.stopPropagation();
        } else if (e.button === 2) {
            this.finishArrow(e);
        }
    }

    grabPiece(e) {
        document.body.style.overflow = 'hidden';
    
        let elem = e.target;
    
        document.body.style.cursor = "grabbing";
    
        if (elem.classList.contains("Piece") && !elem.parentNode.classList.contains("Targettable")) {
    
            this.removeMarks();
            this.squareSelected = elem.parentNode;
            this.squareSelected.classList.add("Selected");
            let legalMoves = this.game.moves({ square: this.squareSelected.id, verbose: true });
            [...legalMoves].forEach((move) => {
                document.getElementById(move.to).classList.add("Targettable");
            });
    
            elem.classList.add("Grabbed");
    
            let offset = vmin(5);
            let x;
            let y;
    
            if (this.isWhiteOnBottom) {
                x = window.scrollX + e.clientX - offset;
                y = window.scrollY + e.clientY - offset;
            } else {
                let marginX = this.boardRef.current.getBoundingClientRect().x;
                let marginY = this.boardRef.current.getBoundingClientRect().y;
                let xOff = this.squareSelected.id.charCodeAt(0) - 'h'.charCodeAt(0);
                let yOff = Number(this.squareSelected.id[1]);
                x = e.clientX - marginX - offset + (xOff * vmin(10));
                y = e.clientY - marginY + offset - (yOff * vmin(10));
            }
    
            elem.style.left = `${x}px`;
            elem.style.top = `${y}px`;
    
            this.pieceGrabbed = elem;

        }
    }
    

    handlePointerMove(e) {
        if (this.pieceGrabbed) {
            const offset = vmin(5);
            let x, y;
    
            if (e.type === "mousemove") {
                x = e.clientX;
                y = e.clientY;
            } else if (e.type === "touchmove") {
                const touch = e.touches[0];
                x = touch.clientX;
                y = touch.clientY;
            }
    
            if (this.isWhiteOnBottom) {
                x = window.scrollX + x - offset;
                y = window.scrollY + y - offset;
                
            } else {
                const marginX = this.boardRef.current.getBoundingClientRect().x;
                const marginY = this.boardRef.current.getBoundingClientRect().y;
                const xOff = this.squareSelected.id.charCodeAt(0) - 'h'.charCodeAt(0);
                const yOff = Number(this.squareSelected.id[1]);
                x = x - marginX - offset + xOff * vmin(10);
                y = y - marginY + offset - yOff * vmin(10);
            }
    
            this.pieceGrabbed.style.left = `${x}px`;
            this.pieceGrabbed.style.top = `${y}px`;

            e.stopPropagation();

        }
    }

    releasePiece(e) {
        document.body.style.overflow = '';

        let targetSquare;
        if (e.type === 'touchend') {
            const touchX = e.changedTouches[0].clientX;
            const touchY = e.changedTouches[0].clientY;
            targetSquare = this.getElementByCoordinates(touchX, touchY);
        } else {
            targetSquare = e.target;
        }
        let from;
        let to;
        let isPromotion = false;

        document.body.style.cursor = "default";
        
        if (this.pieceGrabbed) {
            
            from = this.pieceGrabbed.parentNode.id;

            if (targetSquare?.classList.contains("Tile")) {
                to = targetSquare.id;
            } else if (targetSquare?.classList.contains("Piece")) {
                to = targetSquare.parentNode.id;
            }

            
            if (from && to) {
                if ((this.pieceGrabbed.classList.contains("P") && to[1] === '8') || (this.pieceGrabbed.classList.contains("p") && to[1] === '1')) {
                    isPromotion = true;
                }
                this.makeMove(from, to, isPromotion, true);
            }

            this.pieceGrabbed.classList.remove("Grabbed");
            this.pieceGrabbed = null;

        } else if (this.squareSelected && this.squareSelected.childNodes[1] && targetSquare) {

            from = this.squareSelected.id;

            if (targetSquare.classList.contains("Tile")) {
                to = targetSquare.id;
            } else if (targetSquare.classList.contains("Piece")) {
                to = targetSquare.parentNode.id;
            }

            if (from && to) {
                if ((this.squareSelected.childNodes[1].classList.contains("P") && to[1] === '8') || (this.squareSelected.childNodes[1].classList.contains("p") && to[1] === '1')) {
                    isPromotion = true;
                }
                this.makeMove(from, to, isPromotion, true);
            }
        }
    }

    getElementByCoordinates(x, y) {
        const elements = document.querySelectorAll('.js-target-chess-tile-or-piece'); 
        for (let element of elements) {
            const rect = element.getBoundingClientRect();
            if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
                return element;
            }
        }
        return null;
    }

    makeMove(from, to, isPromotion, isPlayerMove) {

        if (isPromotion) {

            let legalMoves = this.game.moves({ square: from, verbose: true });
            let isLegal = false;

            [...legalMoves].forEach(e => {
                if (e.to === to) isLegal = true;
            });

            if (isLegal) {

                let target = document.getElementById(to);

                let pieceOnTarget = target.childNodes[1];
                if (pieceOnTarget) {
                    target.removeChild(pieceOnTarget);
                }
                if (this.pieceGrabbed) {
                    target.append(this.pieceGrabbed);
                } else {
                    if (this.squareSelected) {
                        target.append(this.squareSelected.childNodes[1]);
                    } else {
                        target.append(document.getElementById(from).childNodes[1]);
                    }
                }

                this.promotingMove = { from: from, to: to };
                this.capturedPiece = pieceOnTarget?.classList[2];

                if (typeof (isPromotion) === "string") {

                    this.promoteTo(isPromotion, isPlayerMove);

                } else {

                    document.getElementById("promotionModal").removeAttribute("disabled");

                }

            }

        } else {

            let move = this.game.move({ from: from, to: to });

            if (move) {

                this.playSound(move);

                let target = document.getElementById(to);

                let pieceOnTarget = target.childNodes[1];
                if (pieceOnTarget) {
                    target.removeChild(pieceOnTarget);
                }
                if (this.pieceGrabbed) {
                    target.append(this.pieceGrabbed);
                } else {
                    if (this.squareSelected) {
                        target.append(this.squareSelected.childNodes[1]);
                    } else {
                        target.append(document.getElementById(from).childNodes[1]);
                    }
                }

                if (move.flags.includes("e")) {
                    let square = move.to[0] + move.from[1];
                    let eatedPawn = document.getElementById(square);
                    eatedPawn.removeChild(eatedPawn.childNodes[1]);
                }

                if (move.flags.includes("k")) {
                    let fromSquare = "h" + move.from[1];
                    let toSquare = "f" + move.from[1];
                    document.getElementById(toSquare).append(document.getElementById(fromSquare).childNodes[1]);
                }

                if (move.flags.includes("q")) {
                    let fromSquare = "a" + move.from[1];
                    let toSquare = "d" + move.from[1];
                    document.getElementById(toSquare).append(document.getElementById(fromSquare).childNodes[1]);
                }

                if (this.props.onMove && typeof (this.props.onMove) === "function" && isPlayerMove) {
                    this.props.onMove(from + to, this.game.fen(), move.san, move.flags);
                }

                if (this.props.onComputerMove && typeof (this.props.onComputerMove) === "function" && !isPlayerMove) {
                    this.props.onComputerMove(from + to, this.game.fen(), move.san, move.flags);
                }

                if (this.props.onFenUpdate && typeof (this.props.onFenUpdate) === "function") {
                    this.props.onFenUpdate(this.game.fen(), move);
                }

                if (this.props.onCapture && typeof (this.props.onCapture) === "function" && move.flags.includes("c")) {
                    this.props.onCapture(pieceOnTarget.classList[2]);
                }

                this.removeMarks();

                this.markLastMove(from, to);

                this.isGameOver();

                this.squareSelected = null;
            }

        }

    }

    promoteTo(piece, isPlayerMove) {

        document.getElementById("promotionModal").setAttribute("disabled", true);

        let move = this.game.move({ from: this.promotingMove.from, to: this.promotingMove.to, promotion: piece });

        if (this.promotingMove && move) {

            this.playSound(move);

            let promotedPiece = document.getElementById(this.promotingMove.to).childNodes[1];

            if (promotedPiece.classList.contains("P")) {
                promotedPiece.classList.replace("P", piece.toUpperCase());
            } else if (promotedPiece.classList.contains("p")) {
                promotedPiece.classList.replace("p", piece);
            }

            if (this.props.onMove && typeof (this.props.onMove) === "function" && isPlayerMove) {
                this.props.onMove(this.promotingMove.from + this.promotingMove.to + piece, this.game.fen(), move.san, move.flags);
            }

            if (this.props.onComputerMove && typeof (this.props.onComputerMove) === "function" && !isPlayerMove) {
                this.props.onComputerMove(this.promotingMove.from + this.promotingMove.to + piece, this.game.fen(), move.san, move.flags);
            }

            if (this.props.onFenUpdate && typeof (this.props.onFenUpdate) === "function") {
                this.props.onFenUpdate(this.game.fen(), move);
            }

            if (this.props.onCapture && typeof (this.props.onCapture) === "function" && move.flags.includes("c")) {
                this.props.onCapture(this.capturedPiece);
            }

        }

        this.removeMarks();

        this.markLastMove(this.promotingMove.from, this.promotingMove.to);

        this.isGameOver();

        this.promotingMove = null;

        this.squareSelected = null;

    }

    playAudio(sound) {
        sound.play().catch((error) => {
            console.warn("Error playing audio:", error);
        });
    }
    
    playSound(move) {
        if (this.game.game_over()) {
            this.playAudio(ggSound);
        } else if (this.game.in_check()) {
            this.playAudio(checkSound);
        } else {
            if (move.flags.includes("n") || move.flags.includes("b")) {
                this.playAudio(moveSound);
            }
    
            if (move.flags.includes("c") || move.flags.includes("e")) {
                this.playAudio(captureSound);
            }
    
            if (move.flags.includes("k") || move.flags.includes("q")) {
                this.playAudio(castleSound);
            }
        }
    }

    startArrow(e) {

        let square = e.target;

        if (square.classList.contains("Tile")) {
            this.arrowFrom = square;
        } else if (square.classList.contains("Piece")) {
            this.arrowFrom = square.parentNode;
        }

    }

    finishArrow(e) {
        if (this.arrowFrom) {
            let square = e.target;
            let arrowTo = null;

            if (square.classList.contains("Tile")) {
                arrowTo = square;
            } else if (square.classList.contains("Piece")) {
                arrowTo = square.parentNode;
            }

            if (arrowTo) {
                if (arrowTo === this.arrowFrom) {
                    arrowTo.classList.add("Highlighted")
                } else {
                    this.drawArrow(this.arrowFrom, arrowTo)
                }
            }
        }

        this.arrowFrom = null;
    }

    drawArrow(from, to) {
        // Setup
        const canvas = document.getElementById("arrowCanvas");
        const context = canvas.getContext("2d");
        const offset = vmin(5);                     // Offset to center arrows in cells
        const headLen = offset / 4;                 // Length of the arrowhead
        const headAng = Math.PI / 7;                // Angle of the arrowhead
        const color = "#c62828";                    // Color of the arrow
        context.strokeStyle = color;                // Stroke color of the arrow
        context.fillStyle = color;                  // Fill color of the arrow
        context.lineWidth = offset / 3;             // Width of the arrow stroke
        // Get coordinates of 'from' and 'to' squares
        const getCoordinates = element => ({
          x: element.getBoundingClientRect().left - canvas.getBoundingClientRect().left + offset,
          y: element.getBoundingClientRect().top - canvas.getBoundingClientRect().top + offset
        });
        const { x: fromx, y: fromy } = getCoordinates(from);
        const { x: tox, y: toy } = getCoordinates(to);
        // Calculate the angle of the arrow
        const angle = Math.atan2(toy - fromy, tox - fromx);
        // Draw the main line
        context.beginPath();
        context.moveTo(fromx, fromy);
        context.lineTo(tox, toy);
        context.stroke();
        // Draw the arrowhead
        context.beginPath();
        context.moveTo(tox, toy);
        context.lineTo(tox - headLen * Math.cos(angle - headAng), toy - headLen * Math.sin(angle - headAng));
        context.lineTo(tox - headLen * Math.cos(angle + headAng), toy - headLen * Math.sin(angle + headAng));
        context.closePath();
        context.fill();
        context.stroke();
    }

    markLastMove(from, to) {

        [...document.getElementsByClassName("LastMoved")].forEach((elem) => {
            elem.classList.remove("LastMoved");
        });

        document.getElementById(from).classList.add("LastMoved");
        document.getElementById(to).classList.add("LastMoved");

    }

    isGameOver() {

        [...document.getElementsByClassName("InCheck")].forEach((elem) => {
            elem.classList.remove("InCheck");
        });

        if (this.game.in_check()) {
            if (this.game.turn() === 'w') {
                document.getElementsByClassName("K")[0].classList.add("InCheck");
            } else {
                document.getElementsByClassName("k")[0].classList.add("InCheck");
            }
        }

        if (this.game.in_checkmate()) {
            if (this.game.turn() === 'w') {
                this.endGame("BLACK WON", "checkmate");
            } else {
                this.endGame("WHITE WON", "checkmate");
            }
        }

        if (this.game.in_draw()) {
            if (this.game.insufficient_material()) {
                this.endGame("DRAW", "insufficient material");
            } else if (this.game.in_stalemate()) {
                this.endGame("DRAW", "stalemate");
            } else if (this.game.in_threefold_repetition()) {
                this.endGame("DRAW", "3 repetitions");
            }
        }

    }

    restartGame() {
        this.loadFEN("rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1");
        if (this.props.onGameRestart && typeof (this.props.onGameRestart) === "function") {
            this.props.onGameRestart();
        }
    }

    loadFEN(fenData) {

        this.game.load(fenData);
        let skip = 0;
        let fenPos = 0;
        this.fen = fenData;
        let fenPieces = fenData.split(" ")[0].split('/').join('');

        for (let i = ROWS.length - 1; i >= 0; i--) {
            for (let j = 0; j < COLUMNS.length; j++) {

                let piece;
                if (skip === 0) {
                    if (fenPieces[fenPos].match(/[pbnrqkPBNRQK]/)) {
                        piece = fenPieces[fenPos];
                    } else if (fenPieces[fenPos].match(/[1-8]/)) {
                        skip = Number.parseInt(fenPieces[fenPos]) - 1;
                    }
                    fenPos++
                } else {
                    skip--;
                }

                let square = document.getElementById(COLUMNS[j] + ROWS[i]);
                if (square) {
                    let pieceOnSquare = square.childNodes[1];
                    if (pieceOnSquare) {
                        square.removeChild(pieceOnSquare);
                    }
                    if (piece) {
                        square.innerHTML += renderToString(<Piece pieceName={piece} />);
                    }
                }

            }
        }

        this.removeMarks();
        [...document.getElementsByClassName("LastMoved")].forEach((elem) => {
            elem.classList.remove("LastMoved");
        });
        [...document.getElementsByClassName("InCheck")].forEach((elem) => {
            elem.classList.remove("InCheck");
        });

        if (this.props.onFenUpdate && typeof (this.props.onFenUpdate) === "function") {
            this.props.onFenUpdate(this.game.fen(), null);
        }

    }

    removeMarks() {

        if (this.squareSelected) this.squareSelected.classList.remove("Selected");

        [...document.getElementsByClassName("Targettable")].forEach((elem) => {
            elem.classList.remove("Targettable");
        });

        [...document.getElementsByClassName("Highlighted")].forEach((elem) => {
            elem.classList.remove("Highlighted");
        });

        let c = document.getElementById("arrowCanvas");
        c.getContext('2d').clearRect(0, 0, c.width, c.height);

    }

    resizeCanvas() {
        let c = document.getElementById("arrowCanvas");
        let newSize = vmin(80);
        c.width = newSize;
        c.height = newSize;
    }

    rotateBoard() {
        let c = document.getElementById("arrowCanvas");
        c.getContext('2d').clearRect(0, 0, c.width, c.height);
        if (this.boardRef.current.classList.contains("WhiteOnBottom")) {
            this.boardRef.current.classList.replace("WhiteOnBottom", "BlackOnBottom");
            this.isWhiteOnBottom = false;
            if(this.playerColor !== "both") this.playerColor = "black";
        } else if (this.boardRef.current.classList.contains("BlackOnBottom")) {
            this.boardRef.current.classList.replace("BlackOnBottom", "WhiteOnBottom");
            this.isWhiteOnBottom = true;
            if(this.playerColor !== "both") this.playerColor = "white";
        }
    }

    rotateBoardAnimationLess(){
        this.boardRef.current.classList.add("AnimationLess");
        this.rotateBoard();
        setTimeout(() => {
            this.boardRef.current.classList.remove("AnimationLess");
        }, 100);
    }

    endGame(result, reason){
        if(this.GameOverModal.current.isNotActive()){
            this.GameOverModal.current.setResult(result);
            this.GameOverModal.current.setReason(reason);
            this.GameOverModal.current.showModal();
            ggSound.play();
        }
    }

    hideGameOverModal(){
        this.GameOverModal.current.hideModal();
    }

    getTurn(){
        return this.game.turn()==="w" ? "white" : "black";
    }

    extractFEN(){
        let Fen = "";
        let sum = 0;
        [...this.boardRef.current.childNodes].forEach( (e, i) => {
            if(i < 64){
                let p = e.childNodes[1];
                if(p){
                    if(sum > 0){
                        Fen += sum;
                        sum = 0;
                    }
                    Fen += p.classList[3];
                }else{
                    sum++;
                }
                if((i+1)%8 === 0 && i !== 63){
                    if(sum > 0){
                        Fen += sum;
                        sum = 0;
                    }
                    Fen += "/";
                }
            }
        });
        if(sum > 0){
            Fen += sum;
        }
        return Fen;
    }

    setEditability(cond){
        if(cond){
            this.playerColor = "both";
        }else{
            this.playerColor = "none";
        }
    }

}

function vh(v) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

function vw(v) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

function vmin(v) {
    return Math.min(vh(v), vw(v));
}