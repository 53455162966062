import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Col, Row, Container } from "react-bootstrap";

function ChessDemo() {
  const [level, setLevel] = useState(5); // Default level is 5
  const [color, setColor] = useState("white"); // Default color is white

  const handleLevelChange = (e) => {
    setLevel(e.target.value);
  };

  const handleColorChange = (e) => {
    setColor(e.target.checked ? "black" : "white");
  };

  const computerGameLink = `/portfolio/chessdemo/computergame?level=${level}&color=${color}`;

  return (
    <Container>
      <h2 className="my-8">Chess Demo</h2>
      <Row className="mb-8">
        <Col>
          <Link to="/portfolio/chessdemo/freeboard">
            <Button className="w-100" variant="primary" size="lg">
              Analysis Board
            </Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group controlId="levelRange">
            <Form.Label>Level: {level}</Form.Label>
            <Form.Range
              min={1}
              max={10}
              step={1}
              value={level}
              onChange={handleLevelChange}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Form.Check
            type="switch"
            id="colorSwitch"
            label={`Play as ${color}`}
            checked={color === "black"}
            onChange={handleColorChange}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Link to={computerGameLink}>
            <Button className="w-100" variant="accent4" size="lg">
              Play VS Computer
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default ChessDemo;
